'use strict'

angular
	.module('cb.mailing', [])
	.controller('MailingController', ["$scope", "$rootScope", "MailingService", "$filter", "PickListService", "NotificationService", "orderByFilter", "LeadProviderService", "EnquiryModalService", "ImportTemplateService", "StaffService", "MaintenanceService", function ($scope, $rootScope, MailingService, $filter,
			PickListService, NotificationService, orderByFilter, LeadProviderService, EnquiryModalService,
			ImportTemplateService, StaffService, MaintenanceService) {

			$scope.init = function () {
				moment.defaultFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

				$scope.viewAllPermission = false;
				if ($rootScope.User && $rootScope.User.Roles) {
					$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Sales Person/Fitter').length > 0 ? false : true;
				}
				$scope.Search = {
					ValidEmails: false,
					NewsletterSubscribers: false,
					RoofBlinds: false,
					SideBlinds: false,
					OrderBy: 'OrderDate',
					OrderDirection: 'Asc'
				};
				$scope.Leads = [];
				$scope.LeadStatusData = [];
				$scope.Action;
				$scope.Status = [];
				$scope.LeadProvider = [];
				$scope.openPopups = {};
				$scope.Params = {};
				$scope.ActionPickListData = [];
				$scope.ValidEmails = false;
				$scope.NewsletterSubscribers = false;
				$scope.RoofBlinds = false;
				$scope.SideBlinds = false;
				$scope.IncrementMailMerge = 0;
				
				$scope.LeadSource = null;
				$scope.SourceDetail = null;
				$scope.LeadSourceList = [];

				getParams();
				SalesPersonlist();
				getStatus();
				getActions();
				GetLeadProviderList();

				$scope.selects = [{ id: 0, name: "Lead Provider & Lead Status" }, { id: 1, name: "Lead Source & Source Detail" }, { id: 2, name: "Enquiry Method, Interested In & Site Type" }, { id: 3, name: "Hide Contact Info." }, { id: 4, name: "Hide All Dates" }, { id: 5, name: "Email Marketing" }, { id: 6, name: "Show All" }];
				$scope.selectedValue = 6;
			};


			$scope.sorting = function (mode) {
				$scope.sortsetting = mode;
				$scope.reverse = !$scope.reverse;
			};


			$scope.$watchCollection('[FromOrderDateValue, ToOrderDateValue, FromQuotationDateValue, ToQuotationDateValue, FromEnquiryDateValue, ToEnquiryDateValue, FromIssueDateValue, ToIssueDateValue, FromCompletionDateValue, ToCompletionDateValue]', function () {
				if ($scope.Search) {
					// Order date
					$scope.Search.FromOrderDate = $scope.FromOrderDateValue ? moment.utc(moment($scope.FromOrderDateValue).startOf('day')).format() : null;
					$scope.Search.ToOrderDate = $scope.ToOrderDateValue ? moment.utc(moment($scope.ToOrderDateValue).endOf('day')).format() : null;

					// Quotation date
					$scope.Search.FromQuotationDate = $scope.FromQuotationDateValue ? moment.utc(moment($scope.FromQuotationDateValue).startOf('day')).format() : null;
					$scope.Search.ToQuotationDate = $scope.ToQuotationDateValue ? moment.utc(moment($scope.ToQuotationDateValue).endOf('day')).format() : null;

					// Enquiry date
					$scope.Search.FromEnquiryDate = $scope.FromEnquiryDateValue ? moment.utc(moment($scope.FromEnquiryDateValue).startOf('day')).format() : null;
					$scope.Search.ToEnquiryDate = $scope.ToEnquiryDateValue ? moment.utc(moment($scope.ToEnquiryDateValue).endOf('day')).format() : null;

					// Issue date
					$scope.Search.FromIssueDate = $scope.FromIssueDateValue ? moment.utc(moment($scope.FromIssueDateValue).startOf('day')).format() : null;
					$scope.Search.ToIssueDate = $scope.ToIssueDateValue ? moment.utc(moment($scope.ToIssueDateValue).endOf('day')).format() : null;

					// Completion date
					$scope.Search.FromCompletionDate = $scope.FromCompletionDateValue ? moment.utc(moment($scope.FromCompletionDateValue).startOf('day')).format() : null;
					$scope.Search.ToCompletionDate = $scope.ToCompletionDateValue ? moment.utc(moment($scope.ToCompletionDateValue).endOf('day')).format() : null;
				}
			});

			function getParams() {
				$scope.gettingParamsPromise = PickListService.getPickListByPickListName([
					'ddmProduct',
					'BlindUse',
					'Sales Person',
					'Action',
					'LeadCode',
					'Status',
					'LeadProvider',
					'CnsBuilder',
					'PropertyType',
					'LeadSource',
					'ContactType'
				]);
				$scope.gettingParamsPromise.then(function (output) {
					$scope.Params = output.data.PickListEntries;
					$scope.PickLeadProviders = $scope.Params.filter(item => item.PickListName == 'CnsBuilder');
					$scope.PickLeadProviders.forEach(lp => lp.Excluded = false);
					$scope.LeadSourceList = $scope.Params.filter(item => item.PickListName == 'LeadSource');
				});
			};

			function SalesPersonlist() {
				$scope.gettingSalesPersonlistPromise = StaffService.getStaffByRole('Sales');
				$scope.gettingSalesPersonlistPromise.then(function (out) {
					$scope.SalesPerson = out.data.StaffList;
				});
			};

			function getStatus() {
				$scope.GetAllStatusPromise = MaintenanceService.getAllStatuses();
				$scope.GetAllStatusPromise.then(function (output) {
					$scope.LeadStatusData = output.data.StatusList;
				});
			};

			function getActions() {
				$scope.GetActionPickListPromise = MaintenanceService.getAllActions();
				$scope.GetActionPickListPromise.then(function (output) {
					$scope.ActionPickListData = output.data.ActionList;
				});
			};

			function GetLeadProviderList() {
				$scope.GettingLeadProviderListPromise = LeadProviderService.getAllLeadProviders();
				$scope.GettingLeadProviderListPromise.then(function (out) {
					$scope.LeadProvider = out.data.LeadProviders;
				});
			};

			$scope.ClearSourceDetail = function () {
				$scope.Search.SourceDetail = null;
			}

			$scope.open = function ($event, variable) {
				$event.preventDefault();
				$event.stopPropagation();

				for (var k in $scope.openPopups) {
					$scope.openPopups[k] = false;
				};

				$scope.openPopups[variable] = true;
			};

			$scope.ToggleOrderDirection = function () {
				if ($scope.Search.OrderDirection == 'Asc')
					$scope.Search.OrderDirection = 'Desc';
				else
					$scope.Search.OrderDirection = 'Asc';
			};

			$scope.reset = function () {
				document.querySelectorAll('.ng-valid').forEach(elem => {
					elem.classList.remove('ng-valid');
				});
				$scope.Search = {
					ValidEmails: false,
					NewsletterSubscribers: false,
					RoofBlinds: false,
					SideBlinds: false,
					OrderBy: 'OrderDate',
					OrderDirection: 'Asc'
				};

				$scope.FromOrderDateValue = "";
				$scope.ToOrderDateValue = "";
				$scope.FromQuotationDateValue = "";
				$scope.ToQuotationDateValue = "";
				$scope.FromEnquiryDateValue = "";
				$scope.ToEnquiryDateValue = "";
				$scope.FromIssueDateValue = "";
				$scope.ToIssueDateValue = "";
				$scope.FromCompletionDateValue = "";
				$scope.ToCompletionDateValue = "";
			};

			$scope.submit = function () {

				if ($scope.Search.Action) {
					switch ($scope.Search.Action) {
						case 'List':
							$scope.getLeadsList(false, false);
							break;
						case 'Export':
							// Filter the columns based on the selected value
							let filteredLeads = $scope.Leads.map(lead => {
								let filteredLead = {};
								switch ($scope.selectedValue) {
									case 0: // Lead Provider & Lead Status
										filteredLead = {
											Id: lead.Id,
											Email: lead.Email,
											Title: lead.Title,
											Forename: lead.Forename,
											Surname: lead.Surname,
											Postcode: lead.Postcode,
											EnquiryDate: lead.EnquiryDate,
											IssuedDate: lead.IssuedDate,
											QuotationDate: lead.QuotationDate,
											OrderDate: lead.OrderDate,
											CompletionDate: lead.CompletionDate,
											LeadProvider: lead.LeadProvider,
											LeadStatus: lead.LeadStatus
										};
										break;
									case 1: // Lead Source & Source Detail
										filteredLead = {
											Id: lead.Id,
											Email: lead.Email,
											Title: lead.Title,
											Forename: lead.Forename,
											Surname: lead.Surname,
											Postcode: lead.Postcode,
											EnquiryDate: lead.EnquiryDate,
											IssuedDate: lead.IssuedDate,
											QuotationDate: lead.QuotationDate,
											OrderDate: lead.OrderDate,
											CompletionDate: lead.CompletionDate,
											LeadSource: lead.LeadSource,
											SourceDetail: lead.SourceDetail
										};
										break;
									case 2: // Enquiry Method, Interested In & Site Type
										filteredLead = {
											Id: lead.Id,
											Email: lead.Email,
											Title: lead.Title,
											Forename: lead.Forename,
											Surname: lead.Surname,
											Postcode: lead.Postcode,
											EnquiryDate: lead.EnquiryDate,
											IssuedDate: lead.IssuedDate,
											QuotationDate: lead.QuotationDate,
											OrderDate: lead.OrderDate,
											CompletionDate: lead.CompletionDate,
											EnquiryMethod: lead.EnquiryMethod,
											InterestedIn: lead.InterestedIn,
											SiteType: lead.SiteType
										};
										break;
									case 3: // Hide Contact Info
										filteredLead = {
											Id: lead.Id,
											EnquiryDate: lead.EnquiryDate,
											IssuedDate: lead.IssuedDate,
											QuotationDate: lead.QuotationDate,
											OrderDate: lead.OrderDate,
											CompletionDate: lead.CompletionDate
										}; 
										break;
									case 4: // Hide All Dates
										filteredLead = {
											Id: lead.Id,
											Email: lead.Email,
											Title: lead.Title,
											Forename: lead.Forename,
											Surname: lead.Surname,
											Postcode: lead.Postcode
										}; 
										break;
									case 5: // Email Marketing
										filteredLead = {
											Id: lead.Id,
											Email: lead.Email,
											Title: lead.Title,
											Forename: lead.Forename,
											Surname: lead.Surname,
											Postcode: lead.Postcode,
											EnquiryDate: lead.EnquiryDate,
											QuotationDate: lead.QuotationDate,
											LeadStatus: lead.LeadStatus,
											InterestedIn: lead.InterestedIn,
											SiteType: lead.SiteType,
											SalesPerson: lead.SalesPerson
										};
										break;
									case 6: // Show All
										filteredLead = lead; 
										break;
									default:
										filteredLead = lead;
								}
								return filteredLead;
							});

							$scope.ExportFilteredCSV(filteredLeads);
							break;
						case 'MailMerge':
							$scope.MailMerging = true;
							$scope.getLeadsList(false, true);
							break;
						default:
							break;
					};
				} else {
					NotificationService.alert('No Action', 'Select an action to start the process.', 'warning', {});
				};
			};
			$scope.ExportFilteredCSV = function (filteredLeads) {
				var list = [];
				filteredLeads.forEach(function (current) {
					delete current.$$hashKey;
					var temp = {};
					for (var k in current) {
						temp[k] = current[k];
					}
					list.push(temp);
				});

				promiseJSONToCSVConvertor(JSON.stringify(list), "Mailing Export", true);
			};

			$scope.getLeadsList = function (csv, mailMerge) {
				Object.keys($scope.Search).forEach(key => {
					if (!$scope.Search[key])
						delete $scope.Search[key];
					if (key.indexOf('Date') >= 0)
						if ($scope.Search[key] != null)
							$scope.Search[key] = new Date($scope.Search[key]).toISOString();
				});
				$scope.sendingEnquiryPromise = MailingService.GetMailingLeads($scope.Search);
				$scope.sendingEnquiryPromise.then(function (output) {
					$scope.Leads = output.data.Leads;
					//console.log(JSON.stringify($scope.Leads));

					if (csv)
						ExportCSV();
					if (mailMerge)
						$scope.SetUpMalingBatch();
				}, function (error) {
					NotificationService.alert('Mailing Error', 'There was an error sending the mailing data.', 'error', {});
				});
			};

			function ExportCSV() {
				var list = [];
				$scope.Leads.forEach(function (current) {
					delete current.$$hashKey;
					var temp = {};
					for (var k in current) {
						temp[k] = current[k];
					}
					list.push(temp);
				});
				promiseJSONToCSVConvertor(JSON.stringify(list), "Mailing Export", true);
			};

			function promiseJSONToCSVConvertor(JSONData, reportTitle, showLabel) {
				var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData, CSV = '';
				CSV += reportTitle + '\r\n\n';

				if (showLabel) {
					var row = "";
					for (var index in arrData[0]) {
						index = index.replace(/([a-z])([A-Z])/g, '$1 $2');
						row += index + ',';
					};
					row = row.slice(0, -1);
					CSV += row + '\r\n';
				};

				for (var i = 0; i < arrData.length; i++) {
					var row = "";
					for (var index in arrData[i]) {
						row += '"' + arrData[i][index] + '",';
					};
					row.slice(0, row.length - 1);
					CSV += row + '\r\n';
				};

				if (CSV == '')
					return;

				var fileName = "Logs_";

				if (reportTitle)
					fileName += reportTitle.replace(/\s/g, "_");

				downloadFile(fileName + '.csv', 'text/csv', CSV)
			};

			function downloadFile(filename, mimeType, csvContent) {
				filename = filename.replace(/['"]+/g, '');
				var blob = new Blob([csvContent]);
				var needsClick = true;

				if (navigator.appVersion.toString().indexOf('.NET') > 0)
					window.navigator.msSaveBlob(blob, filename);

				else if (window.URL) {
					var evt = document.createEvent("HTMLEvents");
					evt.initEvent("click", null, null);

					var a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";
					var url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = filename;
					a.click();
					//window.URL.revokeObjectURL(url);
				} else {
					if (document.createEvent) {
						var link = document.createElement("a");
						link.setAttribute("href", encodeURI("data:" + mimeType + "," + csvContent));
						link.setAttribute("download", filename);
						var e = document.createEvent('MouseEvents');
						e.initEvent('click', true, true);
						needsClick = link.dispatchEvent(e);
					};

					if (needsClick)
						window.navigator.msSaveBlob(blob, filename);
				};
				if (blob != null) {
					var extension = $rootScope.MimeArray.find(arr => arr.mime === mimeType).ext;
					$scope.UploadPromise = EnquiryModalService.BlobFileUpload(blob, 'Export', 'Logs_Mailing_Export', extension);
					$scope.UploadPromise.then(function (output) {
						if (output.data.renameError == true) {
							NotificationService.alert('File already exists', 'The file already exists. Please rename the file, then upload it manually.', 'error', {});
						}
						else {
							NotificationService.alert('File Uploaded To Cloud', 'File ' + $scope.upload.filename + ' uploaded successfully.', 'success', {});
							$scope.close();
						}
					}, function (err) {
						NotificationService.alert('Cloud Upload Failed', 'Failed to upload the File.', 'error', {});
					});
				}
				else {
					NotificationService.alert("Cloud Upload Failed", "Could not find file to upload.", "error", {});
				}
				//}
			};

			$scope.SetUpMalingBatch = function () {
				$scope.LeadsProgress = 0;
				$scope.progressValue = 0;
				$scope.success = 0;
				$scope.Falled = 0;
				$scope.BatchesSuccess = 0;
				$scope.FileNames = [];
				$scope.FolderName = '';
				$scope.FileIteration = 0;
				$scope.DocsForDownload = [];
				$scope.DownloadRequests = [];
				$scope.RequestStart = null;
				$scope.RequestEnd = null;
				$scope.BuildingFilesStart = null;
				$scope.DownloadingFilesStart = null;
				$scope.BatchesFailed = 0;
				$scope.MalingBatch = {};
				$scope.listOpen = false;
				$scope.Progressing = true

				var maxLeads = 200, batchInfo = {};
				if ($scope.Leads.length < maxLeads) {
					batchInfo = {
						BatchSize: $scope.Leads.length,
						NextBatchSize: 0,
						CurrentBatch: 0,
						NumberOfBatches: 0,
						MailingSelectionSize: $scope.Leads.length,
						LeadsFound: 0,
						CurrentBatchSucsessfull: false
					};
				} else {
					batchInfo = {
						NumberOfBatches: parseInt($scope.Leads.length / maxLeads),
						MailingSelectionSize: $scope.Leads.length,
						BatchSize: maxLeads,
						CurrentBatch: 0,
						LeadsFound: 0,
						CurrentBatchSucsessfull: false
					};
				};

				$scope.MalingBatch = batchInfo;
				$scope.ExecuteMailingSelection($scope.MalingBatch);
				setTimeout(() => {
					InitCircles();
				}, 10);
			};

			function InitCircles() {
				var circles = document.querySelectorAll('circle');
				if (circles.length) {
					circles.forEach((circle) => {
						var radius = circle.r.baseVal.value;
						var circumference = radius * 2 * Math.PI;

						circle.style.strokeDasharray = `${circumference} ${circumference}`;
						circle.style.strokeDashoffset = `${circumference}`;
					});
				};
			};

			$scope.ExecuteMailingSelection = function (MalingBatch) {
				if (!$scope.FolderName) {
					$scope.TotalLeads = $scope.Leads.length;
					$scope.FolderName = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
						var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
						return v.toString(16);
					});
				};

				$scope.FileIteration++;

				var prosTo = $scope.FileIteration * 10 || 10, prosFrom = $scope.FileIteration == 1 ? 0 : prosTo - 10, file = form.templateUpload != undefined ? form.templateUpload.files[0] : null, data = {
					Action: 'MailMerge',
					FolderName: $scope.FolderName,
					FileIteration: $scope.FileIteration,
					ProspectRecordSelection: {},
					UpdateSelectedRecords: {},
					MalingBatch: MalingBatch,
					Leads: $scope.Leads.slice(prosFrom, prosTo).map(pro => pro.Id).join(', ')
				};

				if (!$scope.BuildingFilesStart)
					$scope.BuildingFilesStart = new Date();

				$scope.RequestStart = new Date();
				$scope.GetMailingSeletionPromise = MailingService.GetMailingSelectionforProspects(data, file);
				$scope.GetMailingSeletionPromise.then(function (output) {
					$scope.RequestEnd = new Date();
					SortTimer('Building');

					var textDecoder = new TextDecoder('utf-8');
					output.data = JSON.parse(textDecoder.decode(output.data));
					$scope.MalingBatch = output.data.BatchInfo;
					$scope.progressValue = ((100 / $scope.TotalLeads) * ($scope.FileIteration * 10));
					AdjustProgressCircles($scope.progressValue, 'Building');

					if ($scope.MalingBatch.CurrentBatchSucsessfull) {
						BatchSuccess(MalingBatch.BatchSize);
						if (output.data.DownloadFile.FilePath != null)
							$scope.FileNames.push(output.data.DownloadFile.FilePath);
					} else {
						BatchFailed(MalingBatch.BatchSize);
					}

					if (($scope.FileIteration * 10) <= $scope.Leads.length) {
						$scope.LeadsProgress = $scope.FileIteration * 10;
						$scope.ExecuteMailingSelection($scope.MalingBatch);
					} else {
						$scope.LeadsProgress = $scope.Leads.length;
						$scope.progressValue = 100;
						AdjustProgressCircles($scope.progressValue, 'Building');
						if ($scope.FileNames != null) {
							$scope.mailMergeDate = moment().format('YYYY-MM-DD_HH-mm-ss');
							$scope.DownloadFile();
						}
					}

				});
			};

			function SortTimer(type) {
				if (type == 'Building') {
					var responseSpeed = (($scope.RequestEnd - $scope.RequestStart) / 1000);
					$scope.BuildingTimeToComplete = Math.floor((responseSpeed * (($scope.MalingBatch.MailingSelectionSize - $scope.MalingBatch.ProspectsFound) / 10)));
					$scope.BuildingTimeElapsed = Math.floor(((new Date() - $scope.BuildingFilesStart) / 1000));
				} else {
					var downloadSpeed = (($scope.DownloadEnd - $scope.DownloadStart) / 1000);
					$scope.DownloadingTimeToComplete = Math.floor((downloadSpeed * ($scope.TotalDownloadRequests - $scope.DownloadRequests.length)));
					$scope.DownloadingTimeElapsed = Math.floor(((new Date() - $scope.DownloadingFilesStart) / 1000));
				};
			};

			function AdjustProgressCircles(percent, type) {
				var circle = type == 'Building' ? document.getElementById('BuildingProgressCircle') : document.getElementById('DownloadingProgressCircle');
				if (circle) {
					var radius = circle.r.baseVal.value;
					var circumference = radius * 2 * Math.PI;

					const offset = circumference - percent / 100 * circumference;
					circle.style.strokeDashoffset = offset;
				};
			};

			function BatchSuccess(BatchSize) {
				$scope.BatchesSuccess++;
				var total = $scope.BatchesSuccess + $scope.BatchesFailed;
				let value = (100 / total) * $scope.BatchesSuccess
				$scope.success = value;
			};

			function BatchFailed(BatchSize) {
				$scope.BatchesFailed++;
				var total = $scope.BatchesSuccess + $scope.BatchesFailed;
				let value = (100 / total) * $scope.BatchesFailed;
				$scope.Falled = value;
			};

			$scope.DownloadFile = function (data) {
				if (!data) {
					$scope.DownloadingFilesStart = new Date();
					$scope.Downloading = true;
					$scope.DownloadsDone = 1;

					var NamesFrom = 0, NamesTo = 20, Names = $scope.FileNames.map(file => [file.slice(0, file.length - 5), '_Final', file.slice(file.length - 5)].join('')),
						BatchesRemaining = parseFloat((Names.length / 20).toFixed(2));

					for (var i = 0; i < BatchesRemaining; i++) {
						var data = {
							Files: Names.slice(NamesFrom, NamesTo),
							Folder: $scope.FolderName,
							FinalDownload: false
						};
						NamesFrom = NamesTo;
						NamesTo += 20;
						$scope.DownloadRequests.push(data);
					};

					$scope.DownloadRequests[$scope.DownloadRequests.length - 1].FinalDownload = true;
					$scope.TotalDownloadRequests = $scope.DownloadRequests.length;


					if ($scope.DownloadRequests.length)
						$scope.DownloadFile($scope.DownloadRequests[0]);
				} else {
					data.fileName = form.templateUpload.files[0].name.replace(/\.[^/.]+$/, "") + $scope.mailMergeDate;
					$scope.IncrementMailMerge++;
					data.increment = $scope.IncrementMailMerge;
					$scope.DownloadStart = new Date();
					$scope.DownlodeBatchesPromice = MailingService.DownlodeBatches(data);
					$scope.DownlodeBatchesPromice.then((output) => {
						$scope.DownloadEnd = new Date();
						SortTimer('Downloading');
						$scope.downloadingProgressValue = ((100 / $scope.TotalDownloadRequests) * $scope.DownloadsDone);
						AdjustProgressCircles($scope.downloadingProgressValue, 'Downloading');
						$scope.DownloadsDone++;

						$scope.DocsForDownload.push(new Blob([output.data]))

						if (data.FinalDownload) {
							$scope.IncrementMailMerge = 0;
							$scope.DownloadingTimeToComplete = 0;
							SaveFile($scope.DocsForDownload);
							$scope.Downloading = false;
						} else {
							$scope.DownloadRequests.shift();
							$scope.DownloadFile($scope.DownloadRequests[0]);
						};
					}, (error) => {
						NotificationService.alert('Download Error', 'An error occured when attempting to download the batches.', 'failed', {})
					});
				};
			};

			function SaveFile(data) {
				var Zip = new JSZip();

				data.forEach((item, index) => {
					Zip = Zip.file('Doc_' + index + '.docx', item);
				});

				Zip.generateAsync({
					type: "blob"
				}).then(function (content) {
					saveAs(content, "DownloadedBatches.zip");
				});
			};

		}
	])

	.controller('FirstMailingController', ["$scope", "ImportTemplateService", "MailingService", "PickListService", "NotificationService", "$routeParams", "ProspectService", "$timeout", function ($scope, ImportTemplateService, MailingService, PickListService, NotificationService, $routeParams, ProspectService, $timeout) {
		$scope.Today = new Date();
		$scope.openPopups = {};
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};

			$scope.openPopups[variable] = true;
		};

		$scope.init = function () {
			$scope.haveConTypesFiltered = false;
			$scope.getConservatoryType();
			$scope.ProspectRecordSelection = {};
			$scope.ProspectRecordSelection.MailingCount = ">4";
			$scope.ProspectRecordSelection.MailingStatus = "1";
			$scope.getProspectList();
			$scope.inProgress = false;
			$scope.MailMerging = false;
			$scope.DisableActionChange = false;

			$scope.Action = "List";
			$scope.UpdateSelectedRecords = {};
			var initMailDate = $routeParams.due.toString();
			$scope.UpdateSelectedRecords.MailingDate = Date.now();

			$scope.GetMailerContentPromise = MailingService.GetMailerContentByCount(1);
			$scope.GetMailerContentPromise.then(function (output) {
				$scope.MailerContent = output.data.MailerOutputList;
				var defaultMailCode = output.data.MailerOutputList.find(mc => mc.MailingCount == 1 && mc.IsDefault == true);
				var backupMailCode = output.data.MailerOutputList.find(mc => mc.FirstMailingDefaultBU == true && mc.IsDefault == true);
				$scope.UpdateSelectedRecords.MailerCode = defaultMailCode != null || defaultMailCode != undefined ? defaultMailCode.Id : backupMailCode != null || backupMailCode != undefined ? backupMailCode.Id : null;
				$scope.GetDefaultNote();
			});


			$scope.GetPicksPromise = PickListService.getPickListByPickListName(["ddmSiteType", "BuilderGroup"]);
			$scope.GetPicksPromise.then(function (output) {
				$scope.Picks = output.data.PickListEntries;
			});
			
			$scope.GetTemplatesByTypePromise = ImportTemplateService.getTemplatesByType('prospects');
			$scope.GetTemplatesByTypePromise.then(function (output) {
				$scope.DataTemplates = output.data.TemplateList;
			});
		};

		function FilterConservatoryTypes() {
			var arr = [];
			$scope.Picks.forEach(p => {
				if (p.PickListName != "ddmSiteType" || $scope.ConservatoryTypeList.includes(p.PickListEntry)) {
					arr.push(p);
				}
			})
			$scope.Picks = arr;
		}

		$scope.$on('$locationChangeStart', function (event) {
			if ($scope.inProgress == true) {
				var answer = confirm("Unsaved data will be lost, are you sure you want to leave this page?")
				if (!answer) {
					event.preventDefault();
				}
			}
		});

		window.onbeforeunload = function (event) {
			if ($scope.inProgress == true) {
				var message = 'Unsaved data will be lost.Are you sure you want to close the page ?';
				if (typeof event == 'undefined') {
					event = window.event;
				}
				if (event) {
					event.returnValue = message;
				}
				return message;
			}
		}

		$scope.getConservatoryType = function () {

			$scope.GetConservatoryTypePromise = ProspectService.GetConservatoryType($routeParams.due, $routeParams.batchId, 1);
			$scope.GetConservatoryTypePromise.then(function (output) {
				$scope.ConservatoryTypes = output.data.Picklists

			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Conservatory Types List Error', 'There was an error retriving the conservatory types.', 'error', {});
			});
		};

		$scope.getProspectList = function () {
			var recordSelectionData = { ConservatoryType: [$scope.ProspectRecordSelection.ConservatoryType], MailingCount: 1, BuilderGroup: $scope.ProspectRecordSelection.BuilderGroup }
			var data = { Action: $scope.Action, Mailing: 1, Due: $routeParams.due, BatchId: $routeParams.batchId, ImportBatchName: $routeParams.template, ProspectRecordSelection: recordSelectionData, UpdateSelectedRecords: $scope.UpdateSelectedRecords };

			$scope.GetProspectListPromise = ProspectService.getProspectsForFirstMailing(data);
			$scope.GetProspectListPromise.then(function (output) {
				$scope.prospects = output.data.Results;
				$scope.ConservatoryTypeList = output.data.ConservatoryTypes;
				//$scope.StopAtRecord = $scope.prospects.length;
				switch ($scope.Action) {
					case 'Export':
						$scope.DisableActionChange = false;
						Export();
						break;
					case 'MailMerge':
						$scope.DisableActionChange = false;
						MailMerge();
						break;
					default:
						if (!$scope.haveConTypesFiltered) {
							FilterConservatoryTypes();
							$scope.haveConTypesFiltered = true;
						}
						$scope.DisableActionChange = false;
						$scope.StopAtRecord = $scope.prospects.length;
						break;
				}

			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Prospect List Error', 'There was an error retriving the prospect for this batch.', 'error', {});
			});
		};

		$scope.submit = function () {
			if ($scope.UpdateSelectedRecords.MailerCode == null || $scope.UpdateSelectedRecords.MailerCode == undefined)
				NotificationService.alert('No Mail Code Set', 'A mail code has not been set.', 'error', {});
			else {
				$scope.DisableActionChange = true;
				$scope.getProspectList();
			}
		};

		function Export() {
			var list = [];
			StoppedAtRecords();
			$scope.StoppedAtRecords.forEach(function (current) {
				delete current.$$hashKey;
				var temp = {};
				for (var k in current) {
					temp[k] = current[k];
				}
				list.push(temp);
			});
			promiseJSONToCSVConvertor(JSON.stringify(list), "First Mailing Export", true);
			NotificationService.confirm("Mailer Confirmation", "Do you want to mark this mailing as complete?", {}).get().on('pnotify.confirm', function () {
				$scope.inProgress = true;
				CreateMailBatchRecord();
			}).on('pnotify.cancel', function () {
				$scope.inProgress = false;
			});
		}

		function MailMerge() {
			$scope.inProgress = true;
			StoppedAtRecords();
			$scope.SetUpMalingBatch();
		}

		function StoppedAtRecords() {
			$scope.StoppedAtRecords = [];
			if ($scope.StopAtRecord == $scope.prospects.length) {
				$scope.StoppedAtRecords = $scope.prospects;
			}
			else {
				for (var i = 0; i < $scope.StopAtRecord && i < $scope.prospects.length; i++) {
					$scope.StoppedAtRecords.push($scope.prospects[i]);
				}
				if ($scope.StopAtRecord > $scope.prospects.length)
					$scope.StopAtRecord = $scope.prospects.length;
			}
		}

		$scope.SetUpMalingBatch = function () {
			$scope.progressValue = 0;
			$scope.success = 0;
			$scope.Falled = 0;
			$scope.BatchesSuccess = 0;
			$scope.FileNames = [];
			$scope.FolderName = '';
			$scope.FileIteration = 0;
			$scope.DocsForDownload = [];
			$scope.DownloadRequests = [];
			$scope.RequestStart = null;
			$scope.RequestEnd = null;
			$scope.BuildingFilesStart = null;
			$scope.DownloadingFilesStart = null;
			$scope.BatchesFailed = 0;
			$scope.MalingBatch = {}; 
			$scope.listOpen = false;
			$scope.Progressing = true
			$scope.MailMerging = true;

			//NEED TO MANUALLY CREATE THE MAIL BATCH HERE FROM THE EXISTING LIST OF PROSPECTS
			var maxProspects = 10, batchInfo = {};
			if ($scope.StoppedAtRecords.length < maxProspects) {
				batchInfo = {
					BatchSize: $scope.StoppedAtRecords.length,
					NextBatchSize: 0,
					CurrentBatch: 0,
					NumberOfBatches: 0,
					MailingSelectionSize: $scope.StoppedAtRecords.length,
					ProspectsFound: 0,
					CurrentBatchSucsessfull: false
				};
			} else {
				batchInfo = {
					NumberOfBatches: parseInt($scope.StoppedAtRecords.length / maxProspects),
					MailingSelectionSize: $scope.StoppedAtRecords.length,
					BatchSize: maxProspects,
					CurrentBatch: 0,
					ProspectsFound: 0,
					CurrentBatchSucsessfull: false
				};
			};

			$scope.MalingBatch = batchInfo;
			$scope.ExecuteMailingSelection($scope.MalingBatch);
			setTimeout(() => {
				InitCircles();
			}, 10);
		};

		$scope.ExecuteMailingSelection = function (MalingBatch) {
			if (!$scope.FolderName) {
				$scope.TotalProspects = $scope.StoppedAtRecords.length;
				$scope.FolderName = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
					return v.toString(16);
				});
			};

			$scope.FileIteration++;

			var prosTo = $scope.FileIteration * 10 || 10, prosFrom = $scope.FileIteration == 1 ? 0 : prosTo - 10, file = form.templateUpload != undefined ? form.templateUpload.files[0] : null, data = {
				Action: 'MailMerge',
				FolderName: $scope.FolderName,
				FileIteration: $scope.FileIteration,
				ProspectRecordSelection: {},
				UpdateSelectedRecords: {},
				MalingBatch: MalingBatch,
				Prospects: $scope.StoppedAtRecords.slice(prosFrom, prosTo).map(pro => pro.Id).join(', ')
			};

			if (!$scope.BuildingFilesStart)
				$scope.BuildingFilesStart = new Date();

			$scope.RequestStart = new Date();
			$scope.GetMailingSeletionPromise = MailingService.GetMailingSelectionforProspects(data, file);
			$scope.GetMailingSeletionPromise.then(function (output) {
				$scope.RequestEnd = new Date();
				SortTimer('Building');

				var textDecoder = new TextDecoder('utf-8');
				output.data = JSON.parse(textDecoder.decode(output.data));
				$scope.MalingBatch = output.data.BatchInfo;
				$scope.progressValue = ((100 / $scope.TotalProspects) * ($scope.FileIteration * 10));
				AdjustProgressCircles($scope.progressValue, 'Building');

				if ($scope.MalingBatch.CurrentBatchSucsessfull) {
					BatchSuccess(MalingBatch.BatchSize);
					if (output.data.DownloadFile.FilePath != null)
						$scope.FileNames.push(output.data.DownloadFile.FilePath);
				} else {
					BatchFailed(MalingBatch.BatchSize);
				}

				if (($scope.FileIteration * 10) < $scope.StoppedAtRecords.length) {
					$scope.ExecuteMailingSelection($scope.MalingBatch);
				} else {
					$scope.progressValue = 100;
					AdjustProgressCircles($scope.progressValue, 'Building');
					if ($scope.FileNames.length) {
						$scope.DownloadFile();
					}
				}

			});
		};

		$scope.DownloadFile = function (data) {
			if (!data) {
				$scope.DownloadingFilesStart = new Date();
				$scope.Downloading = true;
				$scope.DownloadsDone = 1;

				var NamesFrom = 0, NamesTo = 20, Names = $scope.FileNames.map(file => [file.slice(0, file.length - 5), '_Final', file.slice(file.length - 5)].join('')),
					BatchesRemaining = parseFloat((Names.length / 20).toFixed(2));

				for (var i = 0; i < BatchesRemaining; i++) {
					var slice = {
						Files: Names.slice(NamesFrom, NamesTo),
						Folder: $scope.FolderName,
						FinalDownload: false
					};
					NamesFrom = NamesTo;
					NamesTo += 20;
					$scope.DownloadRequests.push(slice);
				};

				$scope.DownloadRequests[$scope.DownloadRequests.length - 1].FinalDownload = true;
				$scope.TotalDownloadRequests = $scope.DownloadRequests.length;


				if ($scope.DownloadRequests.length)
					$scope.DownloadFile($scope.DownloadRequests[0]);
			} else {
				data.FileName = form.templateUpload.files[0].name;
				$scope.DownloadStart = new Date();
				$scope.DownlodeBatchesPromice = MailingService.DownlodeBatches(data);
				$scope.DownlodeBatchesPromice.then((output) => {
					$scope.DownloadEnd = new Date();
					SortTimer('Downloading');
					$scope.downloadingProgressValue = ((100 / $scope.TotalDownloadRequests) * $scope.DownloadsDone);
					AdjustProgressCircles($scope.downloadingProgressValue, 'Downloading');
					$scope.DownloadsDone++;

					$scope.DocsForDownload.push(new Blob([output.data]))

					if (data.FinalDownload) {
						$scope.DownloadingTimeToComplete = 0;
						SaveFile($scope.DocsForDownload);
						$scope.Downloading = false;
					} else {
						$scope.DownloadRequests.shift();
						$scope.DownloadFile($scope.DownloadRequests[0]);
					};
				}, (error) => {
					NotificationService.alert('Download Error', 'An error occured when attempting to download the batches.', 'failed', {})
					$scope.inProgress = false;
				});
			};
			$scope.inProgress = false;
		};

		function promiseJSONToCSVConvertor(JSONData, reportTitle, showLabel) {
			var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData, CSV = '';
			CSV += reportTitle + '\r\n\n';

			if (showLabel) {
				var row = "";
				for (var index in arrData[0]) {
					index = index.replace(/([a-z])([A-Z])/g, '$1 $2');
					row += index + ',';
				};
				row = row.slice(0, -1);
				CSV += row + '\r\n';
			};

			for (var i = 0; i < arrData.length; i++) {
				var row = "";
				for (var index in arrData[i]) {
					row += '"' + arrData[i][index] + '",';
				};
				row.slice(0, row.length - 1);
				CSV += row + '\r\n';
			};

			if (CSV == '')
				return;

			var fileName = "Logs_";

			if (reportTitle)
				fileName += reportTitle.replace(/\s/g, "_");

			downloadFile(fileName + '.csv', 'text/csv', CSV)
		};

		function downloadFile(filename, mimeType, csvContent) {
			filename = filename.replace(/['"]+/g, '');
			var blob = new Blob([csvContent]);
			var needsClick = true;

			if (navigator.appVersion.toString().indexOf('.NET') > 0)
				window.navigator.msSaveBlob(blob, filename);

			else if (window.URL) {
				var evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", null, null);

				$timeout(function () {
					var a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";
					var url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = filename;
					a.click();
				});
				//window.URL.revokeObjectURL(url);
			} else {
				if (document.createEvent) {
					var link = document.createElement("a");
					link.setAttribute("href", encodeURI("data:" + mimeType + "," + csvContent));
					link.setAttribute("download", filename);
					var e = document.createEvent('MouseEvents');
					e.initEvent('click', true, true);
					needsClick = link.dispatchEvent(e);
				};

				if (needsClick)
					window.navigator.msSaveBlob(blob, filename);
			};
		};

		function BatchSuccess(BatchSize) {
			$scope.BatchesSuccess++;
			var total = $scope.BatchesSuccess + $scope.BatchesFailed;
			let value = (100 / total) * $scope.BatchesSuccess
			$scope.success = value;
		};

		function BatchFailed(BatchSize) {
			$scope.BatchesFailed++;
			var total = $scope.BatchesSuccess + $scope.BatchesFailed;
			let value = (100 / total) * $scope.BatchesFailed;
			$scope.Falled = value;
		};

		function SortTimer(type) {
			if (type == 'Building') {
				var responseSpeed = (($scope.RequestEnd - $scope.RequestStart) / 1000);
				$scope.BuildingTimeToComplete = Math.floor((responseSpeed * (($scope.MalingBatch.MailingSelectionSize - $scope.MalingBatch.ProspectsFound) / 10)));
				$scope.BuildingTimeElapsed = Math.floor(((new Date() - $scope.BuildingFilesStart) / 1000));
			} else {
				var downloadSpeed = (($scope.DownloadEnd - $scope.DownloadStart) / 1000);
				$scope.DownloadingTimeToComplete = Math.floor((downloadSpeed * ($scope.TotalDownloadRequests - $scope.DownloadRequests.length)));
				$scope.DownloadingTimeElapsed = Math.floor(((new Date() - $scope.DownloadingFilesStart) / 1000));
			};
		};

		function InitCircles() {
			var circles = document.querySelectorAll('circle');
			if (circles.length) {
				circles.forEach((circle) => {
					var radius = circle.r.baseVal.value;
					var circumference = radius * 2 * Math.PI;

					circle.style.strokeDasharray = `${circumference} ${circumference}`;
					circle.style.strokeDashoffset = `${circumference}`;
				});
			};
		};

		$scope.GetDefaultNote = function () {
			var mailer = $scope.MailerContent.find(m => m.Id == $scope.UpdateSelectedRecords.MailerCode);
			$scope.UpdateSelectedRecords.AddNote = '1st Mailing' + ': ' + mailer.MailerName + '/' + mailer.MailerCode;
		};
		function AdjustProgressCircles(percent, type) {
			var circle = type == 'Building' ? document.getElementById('BuildingProgressCircle') : document.getElementById('DownloadingProgressCircle');
			if (circle) {
				var radius = circle.r.baseVal.value;
				var circumference = radius * 2 * Math.PI;

				const offset = circumference - percent / 100 * circumference;
				circle.style.strokeDashoffset = offset;
			};
		};

		function SaveFile(data) {
			var Zip = new JSZip();

			data.forEach((item, index) => {
				Zip = Zip.file('Doc_' + index + '.docx', item);
			});

			Zip.generateAsync({
				type: "blob"
			}).then(function (content) {
				saveAs(content, "DownloadedBatches.zip");
			});
			NotificationService.confirm("Mailer Confirmation", "Do you want to mark this mailing as complete?", {}).get().on('pnotify.confirm', function () {
				$scope.inProgress = true;
				CreateMailBatchRecord();
			}).on('pnotify.cancel', function () {
				$scope.inProgress = false;
			});
		};

		function updateProspects() {
			var input = {
				UserId: $scope.User.Id,
				prospectIds: $scope.prospectIds,
				newMailingDate: moment($scope.UpdateSelectedRecords.MailingDate),
				newMailBatchId: $scope.newMailBatchId,
				noteContent: $scope.UpdateSelectedRecords.AddNote
			};
			$scope.updateProspectsPromise = MailingService.updateProspects(input);
			$scope.updateProspectsPromise.then(function () {
				$scope.inProgress = false;
				//DISPLAY SUCCESS MESSAGE
				NotificationService.alert('Completed', 'Follow-up mailing completed.', 'success', {});
				alert('Follow-up mailing complete. Updated ' + $scope.prospectIds.length + ' records.');
				//NAVIGATE TO prospect-mailing-diary
				$location.path('/prospect-mailing-diary');
			});
		}

		function CreateMailBatchRecord() {
			var WordTemplate = '';
			if ($scope.Action == 'Export') {
				WordTemplate = 'Logs_First-Mailing_Export.csv'
			}
			else {
				var WordTemplateFile = document.getElementsByName('templateUpload');
				WordTemplate = WordTemplateFile[0].files[0].name;
			}

			$scope.prospectIds = [];
			for (var i = 0; i < $scope.StoppedAtRecords.length; i++) {
				$scope.prospectIds.push($scope.StoppedAtRecords[i].Id);
			}
			var input = {
				ProspectIds: $scope.prospectIds,
				MailingDate: moment($scope.UpdateSelectedRecords.MailingDate),
				//ExportFolder: , commented out until reassessment
				WordTemplate: WordTemplate,
				note: $scope.UpdateSelectedRecords.AddNote,
				StopAtRecord: $scope.StopAtRecord || 0,
				BuilderGroupName: $scope.UpdateSelectedRecords.BuilderGroup,
				MailerCode: $scope.UpdateSelectedRecords.MailerCode,
				UserId: $scope.User.Id, //Created By
				MailerSequence: $scope.ProspectRecordSelection.MailerSequence,
				TotalNumProspects: $scope.prospects.length
			};
			$scope.CreateMailBatchRecordPromise = MailingService.CreateMailBatchRecord(input);
			$scope.CreateMailBatchRecordPromise.then(function (output) {
				$scope.newMailBatchId = output.data.newMailBatchId;
				updateProspects()
			});
		}

		$scope.updateStopAtRecord = function (input) {
			$scope.StopAtRecord = input;
		}
	}])

	.controller('FollowUpMailingController', ["$scope", "$location", "ImportTemplateService", "MailingService", "PickListService", "NotificationService", "DownloadFileHelper", "$routeParams", "ProspectService", "$timeout", function ($scope, $location, ImportTemplateService, MailingService, PickListService, NotificationService, DownloadFileHelper, $routeParams, ProspectService, $timeout) {
		$scope.Today = new Date();
		$scope.openPopups = {};
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.init = function () {

			$scope.haveConTypesFiltered = false;
			$scope.ProspectRecordSelection = {};
			$scope.ProspectRecordSelection.MailingCount = ">4";
			$scope.ProspectRecordSelection.MailingStatus = "1";
			$scope.ProspectRecordSelection.MailerSequence = $routeParams.mailingCount;
			$scope.getProspectList();
			$scope.DisableActionChange = false;
			$scope.Action = "List";
			$scope.UpdateSelectedRecords = {};
			var initMailDate = $routeParams.due.toString();
			$scope.UpdateSelectedRecords.MailingDate = Date.now();
			$scope.inProgress = false;
			$scope.MailMerging = false;

			$scope.GetMailerContentPromise = MailingService.GetMailerContentByCount($routeParams.mailingCount);
			$scope.GetMailerContentPromise.then(function (output) {
				$scope.MailerContent = output.data.MailerOutputList;
				var mailSequence = parseInt($scope.ProspectRecordSelection.MailerSequence) + 1;
				var defaultMailCode = output.data.MailerOutputList.find(mc => mc.MailingCount == mailSequence && mc.IsDefault == true);
				var backupMailCode = null;
				if (defaultMailCode == null || defaultMailCode == undefined) {
					switch (mailSequence) {
						case 2:
							backupMailCode = output.data.MailerOutputList.find(mc => mc.FollowUpDefaultBU1st == true && mc.IsDefault == true);
							break;
						case 3:
							backupMailCode = output.data.MailerOutputList.find(mc => mc.FollowUpDefaultBU2nd == true && mc.IsDefault == true);
							break;
						case 4:
							backupMailCode = output.data.MailerOutputList.find(mc => mc.FollowUpDefaultBU3rd == true && mc.IsDefault == true);
							break;
						case 5:
							backupMailCode = output.data.MailerOutputList.find(mc => mc.FollowUpDefaultBU4th == true && mc.IsDefault == true);
							break;
						default:
							break;
					}
				}
				$scope.UpdateSelectedRecords.MailerCode = defaultMailCode != null || defaultMailCode != undefined ? defaultMailCode.Id : backupMailCode != null || backupMailCode != undefined ? backupMailCode.Id : null;
				$scope.GetDefaultNote();
			});

			$scope.GetPicksPromise = PickListService.getPickListByPickListName(["ddmSiteType", "BuilderGroup"]);
			$scope.GetPicksPromise.then(function (output) {
				$scope.Picks = output.data.PickListEntries;
			});

			$scope.GetTemplatesByTypePromise = ImportTemplateService.getTemplatesByType('prospects');
			$scope.GetTemplatesByTypePromise.then(function (output) {
				$scope.DataTemplates = output.data.TemplateList;
			});

			$scope.GetConservatoryTypePromise = ProspectService.GetConservatoryType($routeParams.due, $routeParams.batchId, $routeParams.mailingCount);
			$scope.GetConservatoryTypePromise.then(function (output) {
				$scope.ConservatoryTypes = output.data.Picklists
			});

			$scope.GetDefaultNote = function () {
				var mailer = $scope.MailerContent.find(m => m.Id == $scope.UpdateSelectedRecords.MailerCode);
				var mailing = '';

				if ($scope.ProspectRecordSelection.MailerSequence == 2)
					mailing = '2nd Mailing';
				else if ($scope.ProspectRecordSelection.MailerSequence == 3)
					mailing = '3rd Mailing';
				else if ($scope.ProspectRecordSelection.MailerSequence == 4)
					mailing = '4th Mailing';

				if (mailer != undefined)
					$scope.UpdateSelectedRecords.AddNote = mailing + ': ' + mailer.MailerName + '/' + mailer.MailerCode;

			};
		};

		function FilterConservatoryTypes() {
			var arr = [];
			$scope.Picks.forEach(p => {
				if (p.PickListName != "ddmSiteType" || $scope.ConservatoryTypeList.includes(p.PickListEntry)) {
					arr.push(p);
				}
			})
			$scope.Picks = arr;
		}

		$scope.$on('$locationChangeStart', function (event) {
			if ($scope.inProgress == true) {
				var answer = confirm("Mail Merge currently in progress. Are you sure you want to leave this page?")
				if (!answer) {
					event.preventDefault();
				}
			}
		});

		window.onbeforeunload = function (event) {
			if ($scope.inProgress == true) {
				var message = 'Unsaved data will be lost.Are you sure you want to close the page ?';
				if (typeof event == 'undefined') {
					event = window.event;
				}
				if (event) {
					event.returnValue = message;
				}
				return message;
			}
		}

		$scope.getProspectList = function () {
			var recordSelectionData = { ConservatoryType: [$scope.ProspectRecordSelection.ConservatoryType], MailingCount: $routeParams.mailingCount, BuilderGroup: $scope.ProspectRecordSelection.BuilderGroup }
			var data = { Action: $scope.Action, Mailing: $routeParams.mailingCount, Due: $routeParams.due, BatchId: $routeParams.batchId, ImportBatchName: $routeParams.template, ProspectRecordSelection: recordSelectionData, UpdateSelectedRecords: $scope.UpdateSelectedRecords };

			$scope.GetProspectListPromise = ProspectService.getProspectsForFollowupMailing(data);
			$scope.GetProspectListPromise.then(function (output) {
				$scope.prospects = output.data.Results;
				$scope.ConservatoryTypeList = output.data.ConservatoryTypes;
				switch ($scope.Action) {
					case 'Export':
						$scope.DisableActionChange = false;
						Export();
						break;
					case 'MailMerge':
						$scope.DisableActionChange = false;
						MailMerge();
						break;
					default:
						if (!$scope.haveConTypesFiltered) {
							FilterConservatoryTypes();
							$scope.haveConTypesFiltered = true;
						}
						$scope.DisableActionChange = false;
						$scope.StopAtRecord = $scope.prospects.length;
						break;
				}

			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Prospect List Error', 'There was an error retriving the prospect for this batch.', 'error', {});
			});
		};

		$scope.submit = function () {
			if ($scope.UpdateSelectedRecords.MailerCode == null || $scope.UpdateSelectedRecords.MailerCode == undefined)
				NotificationService.alert('No Mail Code Set', 'A mail code has not been set.', 'error', {});
			else {
				$scope.DisableActionChange = true;
				$scope.getProspectList();
			}
		};

		function Export() {
			var list = [];
			StoppedAtRecords()
			//$scope.getProspectList();
			$scope.StoppedAtRecords.forEach(function (current) {
				delete current.$$hashKey;
				var temp = {};
				for (var k in current) {
					temp[k] = current[k];
				}
				list.push(temp);
			});
			promiseJSONToCSVConvertor(JSON.stringify(list), "Follow-up Mailing Export", true);
			NotificationService.confirm("Mailer Confirmation", "Do you want to mark this mailing as complete?", {}).get().on('pnotify.confirm', function () {
				$scope.inProgress = true;
				CreateMailBatchRecord();
			}).on('pnotify.cancel', function () {
				$scope.inProgress = false;
			});
		}

		function MailMerge() {
			$scope.inProgress = true;
			StoppedAtRecords();
			$scope.SetUpMalingBatch();
		}

		function StoppedAtRecords() {
			$scope.StoppedAtRecords = [];
			if ($scope.StopAtRecord == $scope.prospects.length) {
				$scope.StoppedAtRecords = $scope.prospects;
			}
			else {
				for (var i = 0; i < $scope.StopAtRecord && i < $scope.prospects.length; i++) {
					$scope.StoppedAtRecords.push($scope.prospects[i]);
				}
				if ($scope.StopAtRecord > $scope.prospects.length)
					$scope.StopAtRecord = $scope.prospects.length;
			}
		}

		$scope.SetUpMalingBatch = function () {
			$scope.progressValue = 0;
			$scope.success = 0;
			$scope.Falled = 0;
			$scope.BatchesSuccess = 0;
			$scope.FileNames = [];
			$scope.FolderName = '';
			$scope.FileIteration = 0;
			$scope.DocsForDownload = [];
			$scope.DownloadRequests = [];
			$scope.RequestStart = null;
			$scope.RequestEnd = null;
			$scope.BuildingFilesStart = null;
			$scope.DownloadingFilesStart = null;
			$scope.BatchesFailed = 0;
			$scope.MalingBatch = {};
			$scope.listOpen = false;
			$scope.Progressing = true
			$scope.MailMerging = true;

			//NEED TO MANUALLY CREATE THE MAIL BATCH HERE FROM THE EXISTING LIST OF PROSPECTS
			var maxProspects = 10, batchInfo = {};
			if ($scope.StoppedAtRecords.length < maxProspects) {
				batchInfo = {
					BatchSize: $scope.StoppedAtRecords.length,
					NextBatchSize: 0,
					CurrentBatch: 0,
					NumberOfBatches: 0,
					MailingSelectionSize: $scope.StoppedAtRecords.length,
					ProspectsFound: 0,
					CurrentBatchSucsessfull: false
				};
			} else {
				batchInfo = {
					NumberOfBatches: parseInt($scope.StoppedAtRecords.length / maxProspects),
					MailingSelectionSize: $scope.StoppedAtRecords.length,
					BatchSize: maxProspects,
					CurrentBatch: 0,
					ProspectsFound: 0,
					CurrentBatchSucsessfull: false
				};
			};

			$scope.MalingBatch = batchInfo;
			$scope.ExecuteMailingSelection($scope.MalingBatch);
			setTimeout(() => {
				InitCircles();
			}, 10);
		};

		$scope.ExecuteMailingSelection = function (MalingBatch) {
			if (!$scope.FolderName) {
				$scope.TotalProspects = $scope.StoppedAtRecords.length;
				$scope.FolderName = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
					return v.toString(16);
				});
			};

			$scope.FileIteration++;

			var prosTo = $scope.FileIteration * 10 || 10, prosFrom = $scope.FileIteration == 1 ? 0 : prosTo - 10, file = form.templateUpload != undefined ? form.templateUpload.files[0] : null, data = {
				Action: 'MailMerge',
				FolderName: $scope.FolderName,
				FileIteration: $scope.FileIteration,
				ProspectRecordSelection: {},
				UpdateSelectedRecords: {},
				MalingBatch: MalingBatch,
				Prospects: $scope.StoppedAtRecords.slice(prosFrom, prosTo).map(pro => pro.Id).join(', ')
			};

			if (!$scope.BuildingFilesStart)
				$scope.BuildingFilesStart = new Date();

			$scope.RequestStart = new Date();
			$scope.GetMailingSeletionPromise = MailingService.GetMailingSelectionforProspects(data, file);
			$scope.GetMailingSeletionPromise.then(function (output) {
				$scope.RequestEnd = new Date();
				SortTimer('Building');

				var textDecoder = new TextDecoder('utf-8');
				output.data = JSON.parse(textDecoder.decode(output.data));
				$scope.MalingBatch = output.data.BatchInfo;
				$scope.progressValue = ((100 / $scope.TotalProspects) * ($scope.FileIteration * 10));
				AdjustProgressCircles($scope.progressValue, 'Building');

				if ($scope.MalingBatch.CurrentBatchSucsessfull) {
					BatchSuccess(MalingBatch.BatchSize);
					if (output.data.DownloadFile.FilePath != null)
						$scope.FileNames.push(output.data.DownloadFile.FilePath);
				} else {
					BatchFailed(MalingBatch.BatchSize);
				}

				if (($scope.FileIteration * 10) < $scope.StoppedAtRecords.length) {
					$scope.ExecuteMailingSelection($scope.MalingBatch);
				} else {
					$scope.progressValue = 100;
					AdjustProgressCircles($scope.progressValue, 'Building');
					if ($scope.FileNames != null) {
						$scope.DownloadFile();
					}
				}

			});
		};

		$scope.DownloadFile = function (data) {
			if (!data) {
				$scope.DownloadingFilesStart = new Date();
				$scope.Downloading = true;
				$scope.DownloadsDone = 1;

				var NamesFrom = 0, NamesTo = 20, Names = $scope.FileNames.map(file => [file.slice(0, file.length - 5), '_Final', file.slice(file.length - 5)].join('')),
					BatchesRemaining = parseFloat((Names.length / 20).toFixed(2));

				for (var i = 0; i < BatchesRemaining; i++) {
					var slice = {
						Files: Names.slice(NamesFrom, NamesTo),
						Folder: $scope.FolderName,
						FinalDownload: false
					};
					NamesFrom = NamesTo;
					NamesTo += 20;
					$scope.DownloadRequests.push(slice);
				};

				$scope.DownloadRequests[$scope.DownloadRequests.length - 1].FinalDownload = true;
				$scope.TotalDownloadRequests = $scope.DownloadRequests.length;


				if ($scope.DownloadRequests.length)
					$scope.DownloadFile($scope.DownloadRequests[0]);
			} else {
				data.FileName = form.templateUpload.files[0].name;
				$scope.DownloadStart = new Date();
				$scope.DownlodeBatchesPromice = MailingService.DownlodeBatches(data);
				$scope.DownlodeBatchesPromice.then((output) => {
					$scope.DownloadEnd = new Date();
					SortTimer('Downloading');
					$scope.downloadingProgressValue = ((100 / $scope.TotalDownloadRequests) * $scope.DownloadsDone);
					AdjustProgressCircles($scope.downloadingProgressValue, 'Downloading');
					$scope.DownloadsDone++;

					$scope.DocsForDownload.push(new Blob([output.data]))

					if (data.FinalDownload) {
						$scope.DownloadingTimeToComplete = 0;
						SaveFile($scope.DocsForDownload);
						$scope.Downloading = false;
					} else {
						$scope.DownloadRequests.shift();
						$scope.DownloadFile($scope.DownloadRequests[0]);
					};
				}, (error) => {
					NotificationService.alert('Download Error', 'An error occured when attempting to download the batches.', 'failed', {})
					$scope.inProgress = false;
				});
			};
			$scope.inProgress = false;
		};

		function promiseJSONToCSVConvertor(JSONData, reportTitle, showLabel) {
			var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData, CSV = '';
			CSV += reportTitle + '\r\n\n';

			if (showLabel) {
				var row = "";
				for (var index in arrData[0]) {
					index = index.replace(/([a-z])([A-Z])/g, '$1 $2');
					row += index + ',';
				};
				row = row.slice(0, -1);
				CSV += row + '\r\n';
			};

			for (var i = 0; i < arrData.length; i++) {
				var row = "";
				for (var index in arrData[i]) {
					row += '"' + arrData[i][index] + '",';
				};
				row.slice(0, row.length - 1);
				CSV += row + '\r\n';
			};

			if (CSV == '')
				return;

			var fileName = "Logs_";

			if (reportTitle)
				fileName += reportTitle.replace(/\s/g, "_");

			downloadFile(fileName + '.csv', 'text/csv', CSV)
		};

		function downloadFile(filename, mimeType, csvContent) {
			filename = filename.replace(/['"]+/g, '');
			var blob = new Blob([csvContent]);
			var needsClick = true;

			if (navigator.appVersion.toString().indexOf('.NET') > 0)
				window.navigator.msSaveBlob(blob, filename);

			else if (window.URL) {
				var evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", null, null);

				$timeout(function () {
					var a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";
					var url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = filename;
					a.click();
				});
				//window.URL.revokeObjectURL(url);
			}
			else {
				if (document.createEvent) {
					var link = document.createElement("a");
					link.setAttribute("href", encodeURI("data:" + mimeType + "," + csvContent));
					link.setAttribute("download", filename);
					var e = document.createEvent('MouseEvents');
					e.initEvent('click', true, true);
					needsClick = link.dispatchEvent(e);
				};
				if (needsClick)
					window.navigator.msSaveBlob(blob, filename);
			};
		};

		function InitCircles() {
			var circles = document.querySelectorAll('circle');
			if (circles.length) {
				circles.forEach((circle) => {
					var radius = circle.r.baseVal.value;
					var circumference = radius * 2 * Math.PI;

					circle.style.strokeDasharray = `${circumference} ${circumference}`;
					circle.style.strokeDashoffset = `${circumference}`;
				});
			};
		};

		function SortTimer(type) {
			if (type == 'Building') {
				var responseSpeed = (($scope.RequestEnd - $scope.RequestStart) / 1000);
				$scope.BuildingTimeToComplete = Math.floor((responseSpeed * (($scope.MalingBatch.MailingSelectionSize - $scope.MalingBatch.ProspectsFound) / 10)));
				$scope.BuildingTimeElapsed = Math.floor(((new Date() - $scope.BuildingFilesStart) / 1000));
			} else {
				var downloadSpeed = (($scope.DownloadEnd - $scope.DownloadStart) / 1000);
				$scope.DownloadingTimeToComplete = Math.floor((downloadSpeed * ($scope.TotalDownloadRequests - $scope.DownloadRequests.length)));
				$scope.DownloadingTimeElapsed = Math.floor(((new Date() - $scope.DownloadingFilesStart) / 1000));
			};
		};

		function AdjustProgressCircles(percent, type) {
			var circle = type == 'Building' ? document.getElementById('BuildingProgressCircle') : document.getElementById('DownloadingProgressCircle');
			if (circle) {
				var radius = circle.r.baseVal.value;
				var circumference = radius * 2 * Math.PI;

				const offset = circumference - percent / 100 * circumference;
				circle.style.strokeDashoffset = offset;
			};
		};

		function BatchSuccess(BatchSize) {
			$scope.BatchesSuccess++;
			var total = $scope.BatchesSuccess + $scope.BatchesFailed;
			let value = (100 / total) * $scope.BatchesSuccess
			$scope.success = value;
		};

		function BatchFailed(BatchSize) {
			$scope.BatchesFailed++;
			var total = $scope.BatchesSuccess + $scope.BatchesFailed;
			let value = (100 / total) * $scope.BatchesFailed;
			$scope.Falled = value;
		};

		function SaveFile(data) {
			var Zip = new JSZip();

			data.forEach((item, index) => {
				Zip = Zip.file('Doc_' + index + '.docx', item);
			});

			Zip.generateAsync({
				type: "blob"
			}).then(function (content) {
				saveAs(content, "DownloadedBatches.zip");
			});
			NotificationService.confirm("Mailer Confirmation", "Do you want to mark this mailing as complete?", {}).get().on('pnotify.confirm', function () {
				$scope.inProgress = true;
				CreateMailBatchRecord();
			}).on('pnotify.cancel', function () {
				$scope.inProgress = false;
			});
		};

		function updateProspects() {
			var input = {
				UserId: $scope.User.Id,
				prospectIds: $scope.prospectIds,
				newMailingDate: moment($scope.UpdateSelectedRecords.MailingDate),
				newMailBatchId: $scope.newMailBatchId,
				noteContent: $scope.UpdateSelectedRecords.AddNote
			};
			$scope.updateProspectsPromise = MailingService.updateProspects(input);
			$scope.updateProspectsPromise.then(function () {
				$scope.inProgress = false;
				//DISPLAY SUCCESS MESSAGE
				NotificationService.alert('Completed', 'Follow-up mailing completed.', 'success', {});
				alert('Follow-up mailing complete. Updated ' + $scope.prospectIds.length + ' records.');
				//NAVIGATE TO prospect-mailing-diary
				$location.path('/prospect-mailing-diary');
			});
		}

		function CreateMailBatchRecord() {
			var WordTemplate = '';
			if ($scope.Action == 'Export') {
				WordTemplate = 'Logs_Follow-up_Mailing_Export.csv'
			}
			else {
				var WordTemplateFile = document.getElementsByName('templateUpload');
				WordTemplate = WordTemplateFile[0].files[0].name;
			}

			$scope.prospectIds = [];
			for (var i = 0; i < $scope.StoppedAtRecords.length; i++) {
				$scope.prospectIds.push($scope.StoppedAtRecords[i].Id);
			}
			var input = {
				ProspectIds: $scope.prospectIds,
				MailingDate: moment($scope.UpdateSelectedRecords.MailingDate),
				//ExportFolder: , commented out until reassessment
				WordTemplate: WordTemplate,
				note: $scope.UpdateSelectedRecords.AddNote,
				StopAtRecord: $scope.StopAtRecord || 0,
				BuilderGroupName: $scope.UpdateSelectedRecords.BuilderGroup,
				MailerCode: $scope.UpdateSelectedRecords.MailerCode,
				UserId: $scope.User.Id, //Created By
				MailerSequence: $scope.ProspectRecordSelection.MailerSequence,
				TotalNumProspects: $scope.prospects.length
			};
			$scope.CreateMailBatchRecordPromise = MailingService.CreateMailBatchRecord(input);
			$scope.CreateMailBatchRecordPromise.then(function (output) {
				$scope.newMailBatchId = output.data.newMailBatchId;
				updateProspects()
			});
		}

		$scope.updateStopAtRecord = function (input) {
			$scope.StopAtRecord = input;
		}
	}])
	.controller('Follow-Up-LettersController', ["$rootScope", "$scope", "ImportTemplateService", "MailingService", "EnquiryModalService", "PickListService", "NotificationService", "DownloadFileHelper", "$routeParams", "LeadProfileService", function ($rootScope, $scope, ImportTemplateService, MailingService, EnquiryModalService, PickListService, NotificationService, DownloadFileHelper, $routeParams, LeadProfileService) {
		$scope.Today = new Date();
		$scope.openPopups = {};
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};



		$scope.init = function () {

			$scope.followUpLetterIds = $rootScope.followUpLetterIds;
			$scope.mailingsProcessed = 0;

			$scope.successfulMailMerge = false;
			$scope.UpdateSelectedRecords = {};
			$scope.UpdateSelectedRecords.MailingDate = new Date();
			$scope.LeadProfileSelection = {};
			$scope.getLeadProfiles();
			$scope.Action = "List";
			$scope.inProgress = false;
		};

		$scope.getLeadProfiles = function () {
			var data = {
				LeadProfileIds: $scope.followUpLetterIds,
				MailingDate: $scope.UpdateSelectedRecords.MailingDate,
				UpdateMailDateCount: false
			};
			if (data.LeadProfileIds.length == undefined)
				NotificationService.alert('Lead Profile List Error', "There is nothing currently selected. Please reselect the mailings you wish to send.", 'error', {});
			else {
				$scope.GetLeadProfilesPromise = LeadProfileService.getLeadProfilesForMailing(data);
				$scope.GetLeadProfilesPromise.then(function (output) {
					$scope.LeadProfiles = output.data.Results;
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Lead Profile List Error', 'There was an error retriving the lead profiles for this mailing session', 'error', {});
				})
			}
		};

		$scope.start = function () {
			if ($scope.successfulMailMerge == false) {
				$scope.startMerge();
			}
			else {
				NotificationService.confirm("Mailer Confirmation", "Mailing has already had a successful merge. Are you sure you wish to do another mail merge?", {}).get().on('pnotify.confirm', function () {
					$scope.mailingsProcessed = 0;
					$scope.startMerge();
				}).on('pnotify.cancel', function () {

				});
			}
		}

		$scope.startMerge = function () {
			$scope.inProgress = true;
			$scope.MailMerging = 'hello';
			$scope.SetUpMailingBatch();
		};

		$scope.SetUpMailingBatch = function () {
			$scope.progressValue = 0;
			$scope.success = 0;
			$scope.Falled = 0;
			$scope.BatchesSuccess = 0;
			$scope.FileNames = [];
			$scope.FolderName = '';
			$scope.FileIteration = 0;
			$scope.DocsForDownload = [];
			$scope.DownloadRequests = [];
			$scope.RequestStart = null;
			$scope.RequestEnd = null;
			$scope.BuildingFilesStart = null;
			$scope.DownloadingFilesStart = null;
			$scope.BatchesFailed = 0;
			$scope.MailingBatch = {};
			$scope.listOpen = false;
			$scope.Progressing = true

			//NEED TO MANUALLY CREATE THE MAIL BATCH HERE FROM THE EXISTING LIST OF PROSPECTS
			var maxLeadProfiles = 10, batchInfo = {};
			if ($scope.LeadProfiles.length < maxLeadProfiles) {
				batchInfo = {
					BatchSize: $scope.LeadProfiles.length,
					NextBatchSize: 0,
					CurrentBatch: 0,
					NumberOfBatches: 0,
					MailingSelectionSize: $scope.LeadProfiles.length,
					LeadProfilesFound: 0,
					CurrentBatchSucsessfull: false
				};
			} else {
				batchInfo = {
					NumberOfBatches: parseInt($scope.LeadProfiles.length / maxLeadProfiles),
					MailingSelectionSize: $scope.LeadProfiles.length,
					BatchSize: maxLeadProfiles,
					CurrentBatch: 0,
					LeadProfilesFound: 0,
					CurrentBatchSucsessfull: false
				};
			};

			$scope.MailingBatch = batchInfo;
			$scope.ExecuteMailingSelection($scope.MailingBatch);
			setTimeout(() => {
				InitCircles();
			}, 10);
		};

		$scope.ExecuteMailingSelection = function (MailingBatch) {
			if (!$scope.FolderName) {
				$scope.TotalLeadProfiles = $scope.LeadProfiles.length;
				$scope.FolderName = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
					return v.toString(16);
				});
			};

			$scope.FileIteration++;

			var prosTo = $scope.FileIteration * 10 || 10, prosFrom = $scope.FileIteration == 1 ? 0 : prosTo - 10, file = form.templateUpload != undefined ? form.templateUpload.files[0] : null, data = {
				Action: 'MailMerge',
				FolderName: $scope.FolderName,
				FileIteration: $scope.FileIteration,
				LeadProfileRecordSelection: {},
				UpdateSelectedRecords: {},
				MalingBatch: MailingBatch,
				Leads: $scope.LeadProfiles.slice(prosFrom, prosTo).map(pro => pro.Id).join(', ')
			};

			if (!$scope.BuildingFilesStart)
				$scope.BuildingFilesStart = new Date();

			$scope.RequestStart = new Date();
			$scope.GetMailingSeletionPromise = MailingService.GetMailingSelectionforProspects(data, file);
			$scope.GetMailingSeletionPromise.then(function (output) {
				$scope.RequestEnd = new Date();
				SortTimer('Building');

				var textDecoder = new TextDecoder('utf-8');
				output.data = JSON.parse(textDecoder.decode(output.data));
				$scope.MailingBatch = output.data.BatchInfo;
				$scope.progressValue = ((100 / $scope.TotalProspects) * ($scope.FileIteration * 10));
				AdjustProgressCircles($scope.progressValue, 'Building');

				if ($scope.MailingBatch.CurrentBatchSucsessfull) {
					BatchSuccess(MailingBatch.BatchSize);
					$scope.mailingsProcessed = $scope.mailingsProcessed + MailingBatch.BatchSize;
					if (output.data.DownloadFile.FilePath != null)
						$scope.FileNames.push(output.data.DownloadFile.FilePath);
				} else {
					BatchFailed(MailingBatch.BatchSize);
				}

				if (($scope.FileIteration * 10) <= $scope.LeadProfiles.length) {
					$scope.ExecuteMailingSelection($scope.MailingBatch);
				} else {
					$scope.progressValue = 100;
					AdjustProgressCircles($scope.progressValue, 'Building');
					$scope.inProgress = false;
					if ($scope.FileNames != null) {
						$scope.DownloadFile();
					}
				}

			});
		};

		$scope.DownloadFile = function (data) {
			if (!data) {
				$scope.DownloadingFilesStart = new Date();
				$scope.Downloading = true;
				$scope.DownloadsDone = 1;

				var NamesFrom = 0, NamesTo = 20, Names = $scope.FileNames.map(file => [file.slice(0, file.length - 5), '_Final', file.slice(file.length - 5)].join('')),
					BatchesRemaining = parseFloat((Names.length / 20).toFixed(2));

				for (var i = 0; i < BatchesRemaining; i++) {
					var data = {
						Files: Names.slice(NamesFrom, NamesTo),
						Folder: $scope.FolderName,
						FinalDownload: false
					};
					NamesFrom = NamesTo;
					NamesTo += 20;
					$scope.DownloadRequests.push(data);
				};

				$scope.DownloadRequests[$scope.DownloadRequests.length - 1].FinalDownload = true;
				$scope.TotalDownloadRequests = $scope.DownloadRequests.length;


				if ($scope.DownloadRequests.length)
					$scope.DownloadFile($scope.DownloadRequests[0]);
			} else {
				$scope.DownloadStart = new Date();
				$scope.DownlodeBatchesPromice = MailingService.DownlodeBatches(data);
				$scope.DownlodeBatchesPromice.then((output) => {
					$scope.DownloadEnd = new Date();
					SortTimer('Downloading');
					$scope.downloadingProgressValue = ((100 / $scope.TotalDownloadRequests) * $scope.DownloadsDone);
					AdjustProgressCircles($scope.downloadingProgressValue, 'Downloading');
					$scope.DownloadsDone++;

					$scope.DocsForDownload.push(new Blob([output.data]))

					if (data.FinalDownload) {
						$scope.DownloadingTimeToComplete = 0;
						SaveFile($scope.DocsForDownload);
						$scope.Downloading = false;
					} else {
						$scope.DownloadRequests.shift();
						$scope.DownloadFile($scope.DownloadRequests[0]);
					};
				}, (error) => {
					NotificationService.alert('Download Error', 'An error occured when attempting to download the batches.', 'failed', {})
					$scope.inProgress = false;
				});
			};
		};

		function InitCircles() {
			var circles = document.querySelectorAll('circle');
			if (circles.length) {
				circles.forEach((circle) => {
					var radius = circle.r.baseVal.value;
					var circumference = radius * 2 * Math.PI;

					circle.style.strokeDasharray = `${circumference} ${circumference}`;
					circle.style.strokeDashoffset = `${circumference}`;
				});
			};
		};

		function SortTimer(type) {
			if (type == 'Building') {
				var responseSpeed = (($scope.RequestEnd - $scope.RequestStart) / 1000);
				$scope.BuildingTimeToComplete = Math.floor((responseSpeed * (($scope.MailingBatch.MailingSelectionSize - $scope.MailingBatch.LeadProfilesFound) / 10)));
				$scope.BuildingTimeElapsed = Math.floor(((new Date() - $scope.BuildingFilesStart) / 1000));
			} else {
				var downloadSpeed = (($scope.DownloadEnd - $scope.DownloadStart) / 1000);
				$scope.DownloadingTimeToComplete = Math.floor((downloadSpeed * ($scope.TotalDownloadRequests - $scope.DownloadRequests.length)));
				$scope.DownloadingTimeElapsed = Math.floor(((new Date() - $scope.DownloadingFilesStart) / 1000));
			};
		};

		function AdjustProgressCircles(percent, type) {
			var circle = type == 'Building' ? document.getElementById('BuildingProgressCircle') : document.getElementById('DownloadingProgressCircle');
			if (circle) {
				var radius = circle.r.baseVal.value;
				var circumference = radius * 2 * Math.PI;

				const offset = circumference - percent / 100 * circumference;
				circle.style.strokeDashoffset = offset;
			};
		};

		function BatchSuccess(BatchSize) {
			$scope.BatchesSuccess++;
			var total = $scope.BatchesSuccess + $scope.BatchesFailed;
			let value = (100 / total) * $scope.BatchesSuccess
			$scope.success = value;
		};

		function BatchFailed(BatchSize) {
			$scope.BatchesFailed++;
			var total = $scope.BatchesSuccess + $scope.BatchesFailed;
			let value = (100 / total) * $scope.BatchesFailed;
			$scope.Falled = value;
		};

		function SaveFile(data) {
			var Zip = new JSZip();

			data.forEach((item, index) => {
				Zip = Zip.file('Doc_' + index + '.docx', item);
			});

			Zip.generateAsync({
				type: "blob"
			}).then(function (content) {
				saveAs(content, "DownloadedBatches.zip");
				$scope.inProgress = false;
				$scope.successfulMailMerge = true;
			});
			NotificationService.confirm("Mailer Confirmation", "Do you want to mark this as complete?", {}).get().on('pnotify.confirm', function () {
				$scope.updateLeadProfiles();
			});
		};

		$scope.updateLeadProfiles = function () {
			var data = {
				LeadProfileIds: $scope.followUpLetterIds,
				MailingDate: $scope.UpdateSelectedRecords.MailingDate,
				UpdateMailDateCount: true
			};
			$scope.updateLeadProfilesPromise = LeadProfileService.getLeadProfilesForMailing(data); //handles update for leadprofile follow-up mails
			$scope.updateLeadProfilesPromise.then(function (output) {
				var input = {
					LeadProfileIds: $scope.followUpLetterIds,
				}
				$scope.MultipleNotes = EnquiryModalService.addMultipleNotes(input);
				$scope.MultipleNotes.then(function (output) {

				});
			});
		}
	}])

	.controller('MailingLogController', ["$scope", "$rootScope", "NotificationService", "MailingService", function ($scope, $rootScope, NotificationService, MailingService) {
		$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person').length > 0 ? false : true;

		$scope.init = function () {
			//request from tom sheet row 31
			$scope.WorkbookName = 'Mailing';
			$scope.IdField = 'LeadNo';
			$scope.NoteField = 'Note';
			//end of request from tom sheet row 31

			$scope.MailingType = '';
			$scope.inProgress = false;
		}

		$scope.$on('$locationChangeStart', function (event) {
			if ($scope.inProgress == false) { }
			else {
				var answer = confirm("Mail Merge currently in progress. Are you sure you want to leave this page?")
				if (!answer) {
					event.preventDefault();
				}
			}
		});

		window.onbeforeunload = function (event) {
			if ($scope.inProgress == false) { }
			else {
				var message = 'Unsaved data will be lost.Are you sure you want to close the page ?';
				if (typeof event == 'undefined') {
					event = window.event;
				}
				if (event) {
					event.returnValue = message;
				}
				return message;
			}
		}

		$scope.submit = function () {
			$scope.inProgress = true;
			if ($scope.MailingType == '') {
				NotificationService.alert('Mailing Type Error', 'No mailing type selected. Please select a mailing type and try again.', 'failed', {})
				$scope.inProgress = false;
			}
			else {
				if (document.getElementById('fileUpload').files.length) {
					var file = document.getElementById('fileUpload');
					var mimetypes = ['text/csv', 'application/vnd.ms-excel']
					if (!(mimetypes.indexOf(file.files[0].type) != -1)) {
						NotificationService.alert('Wrong file type', 'The file that was uploaded was of the wrong file type.', 'alert', {});
						$scope.inProgress = false;
						return false;
					}
					
					var Payload = [];
					if (typeof (FileReader) != "undefined") {
						var reader = new FileReader();
						reader.onload = function (e) {
							var rows = e.target.result.split("\n");
							for (var i = 1; i < rows.length; i++) {
								rows[i].replace(/[\n\r]+/g, '');
								var cells = rows[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
								Payload.push({ "Id": cells[0], "Note": cells[1] });
							}

							if (Payload[Payload.length - 1].length == 1) { Payload.splice(Payload.length - 1, 1); }
							var data = {
								Payload: Payload,
								MailingType: $scope.MailingType,
								WorkbookName: $scope.WorkbookName,
								IdField: $scope.IdField,
								NoteField: $scope.NoteField,
								UserId: $scope.User.Id
							};
							$scope.UpdateMailingPromise = MailingService.UpdateMailing(data);
							$scope.UpdateMailingPromise.then(function (output) {
								NotificationService.alert('Mailing Update', 'All mailings dates successfully updated.', 'success', {});
								$scope.inProgress = false;
							}, function (error) {
								NotificationService.alert('Update Mailing Error', 'There was an error updating the mailing entries. Please check the data and try again', 'error', {});
								$scope.inProgress = false;
							});
						}
						reader.readAsText($("#fileUpload")[0].files[0]);
					}
					else {
						alert("This browser does not support HTML5.");
						$scope.inProgress = false;
					}
				}
				else {
					NotificationService.alert('No File Uploaded', 'There was no file uploaded, please make sure you add a file.', 'alert', {});
					$scope.inProgress = false;
				}
			}
		}
	}])

